import { Leaderboard } from "model/leaderboard";
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/query/axiosBaseQuery";

export const LeaderboardApi = createApi({
    reducerPath: "LeaderboardApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["sharing"],
    endpoints: (builder) => ({
        getLeaderboards: builder.query<Leaderboard[], void>({
            query() {
                return {
                    url: "/common/leaderboards",
                    method: "GET"
                };
            },
            transformResponse: (response: any) => response.data ?? [],
        }),
    }),
});

export const { useLazyGetLeaderboardsQuery } = LeaderboardApi;
export default LeaderboardApi;
