import { Box, Flex, Icon } from "@chakra-ui/react";
import { range, reverse } from "lodash";
import { FC, useEffect, useState } from "react";
import { GoStarFill, GoStar } from "react-icons/go";

interface Props {
  onChange: (data: number) => void;
  value?: number;
  isDisabled?: boolean;
  fontSize?: any
}
const StarReview: FC<Props> = ({ onChange, value, isDisabled, fontSize }) => {
  const defaultStar = 5;
  const [star, setStar] = useState(value ?? 0);

  useEffect(() => {
    if (value) {
      setStar(defaultStar - value);
    }
  }, [value]);

  useEffect(() => {
    onChange(defaultStar - star);
  }, [star]);

  const outline = range(0, defaultStar).map((item,i) => {
    return (
      <Box key={item}>
        {item < star ? (
          <Box key={item}
            cursor={"pointer"}
            mr={1}
            onClick={() => {
              if (isDisabled == true) return;
              setStar(item);
              onChange(defaultStar - star);
            }}
          >
            <Icon as={GoStar} fontSize={fontSize} color={"gray.400"} />
          </Box>
        ) : (
          <Box key={item}
            cursor={"pointer"}
            mr={1}
            onClick={() => {
              if (isDisabled == true) return;
              setStar(item);
              onChange(defaultStar - star);
            }}
          >
            <Icon as={GoStarFill} color={"yellow.400"} fontSize={fontSize} />
          </Box>
        )}
      </Box>
    );
  });

  return (
    <>
      <Flex>{reverse(outline)}</Flex>
    </>
  );
};

StarReview.defaultProps={
  fontSize: "xl"
}

export { StarReview };
