
import { Coupon } from "model/coupon";
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/query/axiosBaseQuery";

export const couponApi = createApi({
  reducerPath: "couponApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    checkCoupon: builder.mutation<Coupon, any>({
      query(code) {
        return {
          url: "/common/coupon/check/"+code,
          method: "POST",
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),
    checkCouponCourse: builder.mutation<Coupon, any>({
      query({code,id_course}) {
        return {
          url: "/common/coupon/check/"+code+"/"+id_course,
          method: "POST",
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    })
  }),
});

export const { useCheckCouponMutation, useCheckCouponCourseMutation } = couponApi;
export default couponApi;
