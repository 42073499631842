import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/query/axiosBaseQuery";
import { User } from "model/user";



export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    register: builder.mutation<void, any>({
      query(data) {
        return {
          url: "/user/auth/register",
          method: "POST",
          data,
        };
      },
      transformResponse: (response: any) => response ?? {}
    }),
    forgotPassword: builder.mutation<void, any>({
      query(data) {
        return {
          url: "/user/auth/forgot-password",
          method: "POST",
          data,
        };
      },
      transformResponse: (response: any) => response ?? {}
    }),
    checkToken: builder.mutation<void, any>({
      query(data) {
        return {
          url: "/user/auth/check-token?token="+data,
          method: "POST"
        };
      },
      transformResponse: (response: any) => response ?? {}
    }),
    updatePassword: builder.mutation<void, any>({
      query(data) {
        return {
          url: "/user/auth/update-password",
          method: "POST",
          data
        };
      },
      transformResponse: (response: any) => response ?? {}
    }),
    updateProfile: builder.mutation<void, any>({
      query(data) {
        return {
          url: "/user/auth",
          method: "PATCH",
          data
        };
      },
      transformResponse: (response: any) => response ?? {}
    }),
    getProfile: builder.query<User, void>({
      query() {
        return {
          url: "/user/auth/profile",
          method: "GET"
        };
      },
      transformResponse: (response: any) => response.data ?? {}
    }),
    getProfilePublic: builder.query<any, any>({
      query(slug) {
        return {
          url: "/user/profile-public/" + slug,
          method: "GET"
        };
      },
      transformResponse: (response: any) => response.data ?? {}
    }),
  }),
});

export const { useRegisterMutation, useForgotPasswordMutation, useCheckTokenMutation, useUpdatePasswordMutation, useLazyGetProfileQuery, useUpdateProfileMutation, useGetProfileQuery } = authApi;
export default authApi;
