import axios from "axios";
const httpClient = axios;

httpClient.interceptors.request.use(
  async (config: any) => {
    if (localStorage.getItem("token")) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export { httpClient };
