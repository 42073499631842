import { Discuss } from "model/discuss";
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/query/axiosBaseQuery";

export const discussApi = createApi({
  reducerPath: "discussApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Topics"],
  endpoints: (builder) => ({
    createTopics: builder.mutation<any, any>({
      query(data) {
        return {
          url: "/discuss/course/create",
          method: "POST",
          data,
        };
      },
      transformResponse: (response: any) => response.data ?? [],
      invalidatesTags: ["Topics"],
    }),
    getTopics: builder.query<Discuss[], any>({
      query(id_course) {
        return {
          url: "/discuss/course/topics/" + id_course,
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? [],
      providesTags: ["Topics"],
    }),
    answer: builder.mutation<Discuss, any>({
      query({ id_discuss, data }) {
        return {
          url: "/discuss/course/answer/" + id_discuss,
          method: "POST",
          data,
        };
      },
      transformResponse: (response: any) => response.data ?? [],
      invalidatesTags: ["Topics"],
    }),
    getAnswers: builder.query<Discuss[], any>({
      query(id_message) {
        return {
          url: "/discuss/course/answers/" + id_message,
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? [],
      providesTags: ["Topics"],
    }),
  }),
});

export const { useCreateTopicsMutation, useLazyGetTopicsQuery, useAnswerMutation, useLazyGetAnswersQuery } = discussApi;
export default discussApi;
