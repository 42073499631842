import { Box, Text, Progress, Button } from "@chakra-ui/react";
import { useDropzone, FileRejection } from "react-dropzone";
import prettyBytes from "pretty-bytes";
import axios from "axios";
import { useEffect, useState } from "react";
import { httpClient } from "libs/utils/http-client";
import { Video } from "model/video";

interface AcceptedFile extends File {
  path?: string;
}

export function UploadVideo({
  title,
  onSuccess,
  onProcess,
}: {
  title: string;
  onSuccess: (data: Video) => void;
  onProcess?: (data: boolean) => void;
}) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      "video/mp4": [".mp4"],
    },
  });

  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const handleUpload = async (file: AcceptedFile) => {
    if (onProcess) {
      onProcess(true);
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("collection_id", "bd67b2b0-bb30-42d8-a78c-fc562a2c5977");
    formData.append("libs", "3");
    formData.append("title", title);
    formData.append("flag", "BootcampMaterial");

    try {
      const response = await httpClient.post(
        "https://media-api.mistercoding.com/video/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            if (progressEvent) {
              const total = progressEvent.total ?? 1;
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / total
              );
              setUploadProgress(percentCompleted);
            }
          },
        }
      );
      onSuccess(response.data.data);
      if (onProcess) {
        onProcess(false);
      }
      console.log("File uploaded successfully:", response.data);
      // You can handle further actions here, e.g., updating state, showing a success message, etc.
    } catch (error) {
      console.error("Error uploading file:", error);
      // Handle error accordingly
    }
  };

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      handleUpload(acceptedFiles[0] as AcceptedFile);
    }
  }, [acceptedFiles]);

  return (
    <>
      <Box
        {...getRootProps()}
        bg={"gray.200"}
        p={6}
        py={16}
        rounded={"xl"}
        cursor={"pointer"}
        borderStyle={"dotted"}
        border={"2px solid"}
        borderColor={"gray.500"}
        _hover={{ opacity: 0.7 }}
      >
        <input {...getInputProps()} />
        <Text color={"gray.600"}>
          Drag 'n' drop video here, or click to select files
        </Text>
        {acceptedFiles?.map((item: AcceptedFile) => (
          <Box key={`file-${item.name}`} my={1}>
            <Text fontWeight={"bold"} color={"gray.600"}>
              {item.name} - {prettyBytes(item.size)}
            </Text>
          </Box>
        ))}
      </Box>

      {acceptedFiles.length > 0 && (
        <Box mt={4}>
          <Text mb={2}>Upload Progress:</Text>
          <Progress
            colorScheme={uploadProgress == 100 ? "green" : "blue"}
            value={uploadProgress}
            hasStripe
          />
        </Box>
      )}
    </>
  );
}
