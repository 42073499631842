import { Box, Circle, Flex, Stack, Text } from '@chakra-ui/react'

const steps = [
    { title: 'Pilih Events', description: 'Pilih event yang kamu inginkan & klik tombol Gabung Event (Jika masih tersedia waktunya)' },
    { title: 'Login & Masuk ke halaman dashboard', description: 'Masuk kedalam dashboard kamu, lalu klik menu events kemudian pilih event' },
    { title: 'Klik menu Join', description: 'Tombol Join akan tesedia setelah event dimulai' },
    { title: 'Download sertifikat', description: 'Setelah kamu mengikuti event, kamu bisa mendownload sertifikat kehadiran' },
]


const HowToJoin = () => {
    return (
        <>
            <Box mx={4}>
                <Box borderLeft={"2px solid #E2E8F0"}>
                    <Stack spacing={20}>
                        {
                            steps.map((step, index) => (
                                <Flex>
                                    <Circle minH={14} minW={42} bg={"white"} ml={"-23px"}>
                                        <Circle border={"3px solid #E2E8F0"} minH={10} minW={10} bg={"white"}>
                                            <Text color={"gray.500"} fontWeight={"bold"}>{index + 1}</Text>
                                        </Circle>
                                    </Circle>
                                    <Box pt={2} ml={2}>
                                        <Text color={"gray.600"} fontWeight={"bold"} fontSize={"lg"}>{step.title}</Text>
                                        <Text color={"gray.600"}>{step.description}</Text>
                                    </Box>
                                </Flex>
                            ))
                        }

                    </Stack>
                </Box>
            </Box>
        </>
    )
}

export { HowToJoin };