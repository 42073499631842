import { ActiveOrder } from "model/active-orde";
import { Course } from "model/course";
import { Kelas } from "model/kelas";
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/query/axiosBaseQuery";

export const learnApi = createApi({
  reducerPath: "learnApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getContent: builder.mutation<any, any>({
      query(id_submateri) {
        return {
          url: "/learn/content/" + id_submateri,
          method: "POST",
          data: {},
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),
    getContentFree: builder.mutation<any, any>({
      query(id_submateri) {
        return {
          url: "/learn/content/free/" + id_submateri,
          method: "POST",
          data: {},
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),
    getActiveOrder: builder.query<ActiveOrder, any>({
      query(id_course) {
        return {
          url: "/learn/history/active-order/" + id_course,
          method: "GET",
          data: {},
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),
    createHistoryLearn: builder.mutation<any, any>({
      query(data) {
        return {
          url: "/learn/history/has-learn",
          method: "POST",
          data,
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),
    getHistoryLearn: builder.query<any, any>({
      query({ id_course, id_order }) {
        return {
          url: "/learn/history/has-learn",
          method: "GET",
          params: {
            id_course,
            id_order,
          },
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),

    getKelasDetail: builder.query<Course, any>({
      query({ slug }) {
        return {
          url: "/learn/detail-class/" + slug,
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),
    downloadCertificate: builder.mutation<any, any>({
      query(course_id) {
        return {
          url: "/learn/certificate/generate/" + course_id,
          method: "POST",
          data: {},
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),
  }),
});

export const {
  useGetContentMutation,
  useLazyGetActiveOrderQuery,
  useCreateHistoryLearnMutation,
  useLazyGetHistoryLearnQuery,
  useLazyGetKelasDetailQuery,
  useGetContentFreeMutation,
  useDownloadCertificateMutation
} = learnApi;
export default learnApi;
