import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { BreadCrumbItem } from "../../model/breadcrumb";
import { FC } from "react";
import Link from "next/link";

interface Props {
  items: BreadCrumbItem[];
}
export const BreadCrumb: FC<Props> = ({ items }) => {
  return (
    <>
      <Breadcrumb m={0} p={0}>
        {items.map((item, i) => {
          return (
            <BreadcrumbItem m={0} p={0} key={i} isCurrentPage={!item.url}>
              {item.url && (
                <BreadcrumbLink as={Link} href={item.url}>
                  {item.title}
                </BreadcrumbLink>
              )}
              {!item.url && (
                <BreadcrumbLink>{item.title}</BreadcrumbLink>
              )}
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </>
  );
};
