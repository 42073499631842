import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "@/utils/query/axiosBaseQuery";
import {BannerPromo} from "model/json_data"

export const jsonApi = createApi({
  reducerPath: "jsonApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getJson: builder.query<any, any>({
      query(flag) {
        return {
          url: "/json/find/"+flag,
          method: "GET",
        };
      },
      transformResponse: (response: any) => response ?? {},
    }),
    getJsonPromoBanner: builder.query<BannerPromo, any>({
      query(flag) {
        return {
          url: "/json/find/"+flag,
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? {},
    })
  }),
});

export const { useGetJsonQuery, useLazyGetJsonQuery, useGetJsonPromoBannerQuery, useLazyGetJsonPromoBannerQuery } = jsonApi;
export default jsonApi;
