import {
    Checkbox,
    FormControl,
    FormErrorMessage,
    FormLabel,
} from "@chakra-ui/react";
import { FC } from "react";
import { Controller } from "react-hook-form";

interface Props {
    register?: any;
    errors?: any;
    title: string;
    label?: string;
    control?: any;
}
export const FormCheckbox: FC<Props> = ({ errors, title, label, control, register }) => {
    return (
        <>
            <FormControl isInvalid={title in errors}>
                <Controller
                    name={title}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Checkbox
                            onChange={onChange}
                            isChecked={value} {...register}
                        >
                            {label}
                        </Checkbox>
                    )}
                />
                <FormErrorMessage>{errors?.[title]?.message}</FormErrorMessage>
            </FormControl>
        </>
    );
};
