import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { FC, useRef, useState } from "react";
import { Controller } from "react-hook-form";

interface Props {
  register?: any;
  errors?: any;
  title: string;
  label?: string;
  control?: any;
}
export const FormFile: FC<Props> = ({ errors, title, label, control }) => {
  const fileRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <FormControl isInvalid={title in errors}>
        <FormLabel>{label}</FormLabel>
        <Controller
          name={title}
          control={control}
          render={({ field: { onChange, onBlur } }) => (
            <Input
              as={"input"}
              type="file"
              ref={fileRef}
              p={4}
              h={16}
              onBlur={onBlur}
              onChange={(e) => {
                if (e.target.files) {
                  onChange(e.target.files[0]);
                }
              }}
            />
          )}
        />
        <FormErrorMessage>{errors?.[title]?.message}</FormErrorMessage>
      </FormControl>
    </>
  );
};
