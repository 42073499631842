import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "@/utils/query/axiosBaseQuery";
import { FlashSale } from 'model/flashsale';

export const flashsaleApi = createApi({
    reducerPath: "flashsaleApi",
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
        getFlashSales: builder.query<FlashSale[], void>({
            query() {
                return {
                    url: "/common/coupon/flashsale",
                    method: "GET",
                };
            },
            transformResponse: (response: any) => response.data ?? {},
        })
    }),
});

export const { useGetFlashSalesQuery } = flashsaleApi;
export default flashsaleApi;
