import { useAppState } from "store/hookstate/app.state";
import { Box } from "@chakra-ui/react";
import { Hourglass } from "react-loader-spinner";

export const Loading = () => {
  const appState = useAppState();
  return (
    <>
      {appState.getLoading() == true && (
        <Box
          height={"100vh"}
          width={"100vw"}
          pos={"fixed"}
          zIndex={10000}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {/* <Spinner size={"xl"} color={"red.500"} /> */}
          {/* <HashLoader color="#0f172a" size={50} /> */}
          {/* <Dna
            visible={true}
            height="120"
            width="120"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          /> */}
          {/* <Audio
            height="100"
            width="100"
            color="#4fa94d"
            ariaLabel="audio-loading"
            wrapperStyle={{}}
            wrapperClass="wrapper-class"
            visible={true}
          /> */}

          <Hourglass
            visible={true}
            height="60"
            width="60"
            ariaLabel="hourglass-loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={["#E53E3E", "#F56565"]}
          />
        </Box>
      )}
    </>
  );
};
