
import { Setting } from "model/setting";
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/query/axiosBaseQuery";

export const settingApi = createApi({
  reducerPath: "settingApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getSetting: builder.query<Setting, any>({
      query(key) {
        return {
          url: "/common/setting/"+key,
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    })
  }),
});

export const { useLazyGetSettingQuery } = settingApi;
export default settingApi;
