import { Box, Icon, Image } from "@chakra-ui/react";
import { FC, useEffect, useRef, useState } from "react";
import { BsCardImage } from "react-icons/bs";

type Props = {
  onChange: (img: any) => void;
  preview?: string;
};
const UploadImage: FC<Props> = ({ onChange, preview }) => {
  const uploadRef = useRef<HTMLInputElement>(null);

  const [previewImage, setPreviewImage] = useState("");

  const handleOpenFile = () => {
    uploadRef.current?.click();
  };
  const handleChangeFile = () => {
    const img = uploadRef.current?.files;
    if (img) {
      setPreviewImage(URL.createObjectURL(img[0]));
      onChange(img[0]);
    }
  };

  useEffect(() => {
    if (preview) {
      setPreviewImage(preview);
    }
  }, [preview]);
  return (
    <>
      <Box
        overflow={"hidden"}
        onClick={handleOpenFile}
        cursor={"pointer"}
        w={"full"}
        minH={40}
        border={"1px solid #CBD5E0"}
        rounded={"xl"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {previewImage != "" ? (
          <>
            <Image src={previewImage} alt="Banner Event" />
          </>
        ) : (
          <>
            <Icon color={"gray.500"} fontSize={80} as={BsCardImage} />
          </>
        )}

        <input
          type="file"
          accept="image/png, image/gif, image/jpeg"
          onChange={handleChangeFile}
          style={{ display: "none" }}
          ref={uploadRef}
        />
      </Box>
    </>
  );
};

export { UploadImage };
