import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import { FC } from "react";
// import Editor from "../Editor";
import { Control, Controller } from "react-hook-form";
import dynamic from "next/dynamic";

const Editor = dynamic(() => import("../Editor"), {
  ssr: false,
});

interface Option {
  value: any;
  label: any;
}

interface Props {
  register?: any;
  errors?: any;
  title: string;
  label?: string;
  control?: any;
  options: Option[];
  isDisabled?: boolean
}
export const FormSelect: FC<Props> = ({
  errors,
  title,
  label,
  control,
  options,
  isDisabled
}) => {
  return (
    <>
      <Controller
        name={title}
        control={control}
        render={({ field: { onChange, onBlur, value, name, ref } }) => (
          <FormControl my={2} isInvalid={title in errors}>
            <FormLabel>{label}</FormLabel>
            <Select isDisabled={isDisabled}
              name={name}
              ref={ref}
              onChange={(e) => {
                onChange(e);
              }}
              onBlur={onBlur}
              value={value}
              placeholder={"Select "+label}
            >
              {options?.map((item) => {
                return (
                  <option value={item.value} key={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </Select>
            <FormErrorMessage>{errors?.[title]?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
    </>
  );
};
