import "@/styles/globals.scss";
import type { AppProps } from "next/app";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { getSession, SessionProvider } from "next-auth/react";
import { StepsTheme as Steps } from "chakra-ui-steps";
import Head from "next/head";
import { Provider } from "react-redux";
import stores from "@/store/stores";
import { useAppStore } from "@/store/zustand/app.store";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { Loading } from "ui";
import { useAppState } from "store/hookstate/app.state";
import { GoogleAnalytics } from "nextjs-google-analytics";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { RecoilRoot } from "recoil";
import { CookiesProvider } from 'react-cookie';
TimeAgo.addDefaultLocale(en);

const theme = extendTheme({
  components: {
    Steps,
  },
  colors: {
    brand: {
      500: "#212121",
      300: "#80891B",
    },
  },
});

const WrapApp = ({ children }: any) => {
  const router = useRouter();
  const appState = useAppState();

  const appStore = useAppStore();
  useEffect(() => {
    getSession()
      .then((res: any) => {
        if (res) {
          appStore.setAccessToken(res.accessToken);
          localStorage.setItem("accessToken", btoa(res.accessToken));
        } else {
          appStore.setAccessToken('');
          localStorage.clear();
        }
      })
      .catch(() => {
        localStorage.clear();
        appStore.setAccessToken('');
      });

    router.events.on("routeChangeStart", () => {
      appState.setLoading(true);
    });
    router.events.on("routeChangeComplete", () => {
      appState.setLoading(false);
    });
  }, [router]);

  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init("1263046240796109");
        ReactPixel.pageView();

        router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      });
  }, [router.events]);

  return (
    <>
      <Loading />
      <RecoilRoot>{children}</RecoilRoot>
    </>
  );
};

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) {
  return (
    <SessionProvider session={session}>
      <Head>
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/images/mister_logo.png"
        ></link>
      </Head>
      <Provider store={stores}>
        <CookiesProvider>
          <ChakraProvider theme={theme}>
            <WrapApp>
              <GoogleAnalytics trackPageViews />
              <Component {...pageProps} />
            </WrapApp>
          </ChakraProvider>
        </CookiesProvider>
      </Provider>
    </SessionProvider>
  );
}
