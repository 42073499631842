import {
    FormControl,
    FormErrorMessage,
    FormLabel,
} from "@chakra-ui/react";
import { FC } from "react";
import { Controller } from "react-hook-form";
import TimePicker from 'react-time-picker';

import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import './FormTime.css';

interface Props {
    register?: any;
    errors?: any;
    title: string;
    label?: string;
    control?: any;
}
export const FormTime: FC<Props> = ({ errors, title, label, control }) => {
    return (
        <>
            <FormControl isInvalid={title in errors}>
                <FormLabel>{label}</FormLabel>
                <Controller
                    name={title}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <TimePicker onChange={onChange} onBlur={onBlur} value={value} className={"form-time"} />
                    )}
                />
                <FormErrorMessage>{errors?.[title]?.message}</FormErrorMessage>
            </FormControl>
        </>
    );
};
