import { hookstate, useHookstate } from "@hookstate/core";

const initialState = hookstate({
    loading: false
});

export const useAppState = () => {
  const state = useHookstate(initialState);
  return {
    getLoading:()=>{
        return state.loading.value;
    },
    setLoading: (param: boolean) => {
      state.loading.set(param);
    },
  };
};
