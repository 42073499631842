import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { BaseQueryFn } from "@reduxjs/toolkit/query";
import { api } from "@/config/api";
import { AuthService } from "@/auth/auth.service";

const authService = new AuthService();

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: api.api_url }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      headers?: AxiosRequestConfig["headers"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params, headers }) => {
    axios.interceptors.request.use(
      async (config: any) => {
        if (authService.accessToken) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${authService.accessToken}`,
          };
        }

        return config;
      },
      (error) => Promise.reject(error)
    );

    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export default axiosBaseQuery;
