import { create } from "zustand";

interface AppState {
  accessToken?: string;
  setAccessToken: (item: string) => void;
}
const useAppStore = create<AppState>((set, get) => ({
  accessToken: "",
  setAccessToken(item) {
    set({
      accessToken: item,
    });
  },
}));

export { useAppStore };
