import { Review } from "model/review";
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/query/axiosBaseQuery";

export const reviewApi = createApi({
  reducerPath: "reviewApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Reviews","Rate"],
  endpoints: (builder) => ({
    getReviews: builder.query<Review[], any>({
      query(id_course) {
        return {
          url: "/discuss/review?id_course=" + id_course,
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? [],
      providesTags: ["Reviews"],
    }),
    getRate: builder.query<any, any>({
        query(id_course) {
          return {
            url: "/discuss/review/rate?id_course=" + id_course,
            method: "GET",
          };
        },
        transformResponse: (response: any) => {
          if(response.data){
            return response.data;
          }
          return null;
          return response;
        },
        providesTags: ["Rate"],
      }),
  }),
});

export const {useLazyGetReviewsQuery,useLazyGetRateQuery} = reviewApi;
export default reviewApi;
