import { ActiveOrder } from "model/active-orde";
import { Kelas } from "model/kelas";
import { Question } from "model/questions";
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/query/axiosBaseQuery";

export const quizApi = createApi({
  reducerPath: "quizApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getQuestion: builder.query<Question[], any>({
      query(slug) {
        return {
          url: "/quiz/questions/" + slug,
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),
    checkPassed: builder.query<void, any>({
      query({id_order,id_course}) {
        return {
          url: "/quiz/check-passed/" + id_order + '/'+id_course,
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),
    submitTest: builder.mutation<any, any>({
      query(data) {
        return {
          url: "/quiz/submit-test",
          method: "POST",
          data: data,
        };
      },
      transformResponse: (response: any) => {
        const data = response.data;
        return data??{};
      },
    }),
  }),
});

export const { useLazyGetQuestionQuery, useSubmitTestMutation, useLazyCheckPassedQuery } = quizApi;
export default quizApi;
