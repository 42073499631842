
import { Chanel } from "model/chanel";
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/query/axiosBaseQuery";

export const orderApi = createApi({
  reducerPath: "orderApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getChanels: builder.query<Chanel[], void>({
      query() {
        return {
          url: "/common/payment/chanels",
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    })
  }),
});

export const { useLazyGetChanelsQuery } = orderApi;
export default orderApi;
