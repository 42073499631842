import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { FC } from "react";
// import Editor from "../Editor";
import { Control, Controller } from "react-hook-form";
import dynamic from "next/dynamic";

const Editor = dynamic(() => import("../Editor"), {
  ssr: false,
});

interface Props {
  register?: any;
  errors?: any;
  title: string;
  label?: string;
  control?: any;
}
export const FormEditor: FC<Props> = ({
  errors,
  title,
  label,
  control,
}) => {
  return (
    <>
      <FormControl isInvalid={title in errors}>
        <FormLabel>{label}</FormLabel>
        <Controller
          name={title}
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Editor onChange={onChange} onBlur={onBlur} value={value} />
          )}
        />
        <FormErrorMessage>{errors?.[title]?.message}</FormErrorMessage>
      </FormControl>
    </>
  );
};
