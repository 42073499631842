import { AuthUtils } from "./auth.utils";
import jwtDecode from "jwt-decode";

export class AuthService {
  private _authenticated = false;

  set accessToken(accessToken: string) {
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
    }
  }

  get accessToken(): string {
    const accessToken = localStorage.getItem("accessToken");

    return accessToken ? atob(accessToken) : "";
  }

  get user(): any {
    if (this.check()) {
      return jwtDecode(this.accessToken);
    }

    return {};
  }

  logout() {
    localStorage.clear();
  }

  check() {
    if (this._authenticated) {
      return true;
    }

    // Check the access token availability
    if (!this.accessToken) {
      return false;
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return false;
    }

    return true;
  }
}
