import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { FC } from "react";
import { Controller } from "react-hook-form";
import DateTimePicker, { DateTimePickerProps } from "react-datetime-picker";

import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

interface Props extends DateTimePickerProps{
  register?: any;
  errors?: any;
  title: string;
  label?: string;
  control?: any;
}
export const FormDateTime: FC<Props> = ({ errors, title, label, control, ...props }) => {
  return (
    <>
      <FormControl isInvalid={title in errors} w={"full"}>
        <FormLabel>{label}</FormLabel>
        <Controller
          name={title}
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <DateTimePicker onChange={onChange} onBlur={onBlur} value={value} {...props}/>
          )}
        />
        <FormErrorMessage>{errors?.[title]?.message}</FormErrorMessage>
      </FormControl>
    </>
  );
};
