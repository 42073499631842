import { Box, Flex, Image } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { cdn2, cdn_mistercoding_img } from "libs/utils/image";

type variant = "lg" | "sm"
type Props = {
    percent: number;
    variant?: variant
}
// https://mrcodingblog.s3.ap-southeast-1.wasabisys.com/rocket2_d979abb58c.svg
const ProgressRocket: FC<Props> = ({ percent, variant }) => {
    const [value,setValue] = useState(0);

    useEffect(()=>{
        setTimeout(()=>{
            setValue(percent)
        },1000)
    },[percent])
    return (
        <>
            <Box my={2} h={variant == "lg" ? 4 : 3} w={"full"} bg={"gray.300"} rounded={"lg"}>
                <Flex>
                    <Box transitionDuration={"3s"} h={variant == "lg" ? 4 : 3} w={`${value}%`} bgGradient={'linear(to-l, gray.200, #7928CA, purple.600)'} rounded={"lg"}></Box>
                    <Image h={variant == "lg" ? 14 : 14} mt={-6} ml={variant == "lg" ? -7 : -6} alt="Rocket Progress" src={cdn_mistercoding_img('Images/rocket.png')} />
                </Flex>
            </Box>
        </>
    )
}

export default ProgressRocket;