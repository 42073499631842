import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "@/utils/query/axiosBaseQuery";
import { Review } from "model/review";

export const commonApi = createApi({
  reducerPath: "commonApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getReviews: builder.query<Review[], void>({
      query() {
        return {
          url: "/common/reviews/all",
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? null,
    }),
  }),
});

export const { useGetReviewsQuery } = commonApi;
export default commonApi;
