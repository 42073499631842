import { Box, Text } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import prettyBytes from "pretty-bytes";
import { useEffect } from "react";

interface AcceptedFile extends File {
  path?: string;
}

export function FormUploadVideo({
  onChange,
}: {
  onChange: (data: File) => void;
}) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      "video/mp4": [".mp4"],
    },
  });

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      onChange(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  return (
    <>
      <Box
        bg={"gray.200"}
        p={6}
        py={16}
        rounded={"xl"}
        cursor={"pointer"}
        borderStyle={"dotted"}
        border={"2px solid"}
        borderColor={"gray.500"}
      >
        <Box {...getRootProps()} _hover={{ opacity: 0.7 }}>
          <input {...getInputProps()} />
          <Text color={"gray.600"} textAlign={"center"}>
            Drag 'n' drop video here, or click to select files
          </Text>
          {acceptedFiles?.map((item: AcceptedFile) => (
            <Box key={`file-${item.name}`} my={1}>
              <Text fontWeight={"bold"} color={"gray.600"} textAlign={"center"}>
                {item.name} - {prettyBytes(item.size)}
              </Text>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
}
