// export const mister_coding_logo = "https://mrcodingblog.s3.ap-southeast-1.wasabisys.com/mistercoding_new_60c7279a36.png";

export const cdn1 = (path: string) => {
    return 'https://cdn1.mistercoding.com/' + path
}

export const cdn1_replace = (path: string) => {
    const host_replace = path.replaceAll("https://s3.ap-southeast-1.wasabisys.com/mistercoding","https://cdn1.mistercoding.com/"+'/mrcoding')
    return host_replace
}


export const cdn_mistercoding = (path: string) => {
    return process.env.NEXT_PUBLIC_ASSETS_IMAGE_HOST+'/mrcoding/' + path
}

export const cdn_mistercoding_img = (path: string) => {
    return process.env.NEXT_PUBLIC_ASSETS_IMAGE_HOST+'/mrcodingimg/' + path
}


export const cdn2 = (path: string) => {
    // return 'https://cdn2.mistercoding.com/' + path
    return process.env.NEXT_PUBLIC_ASSETS_IMAGE_HOST+'/mistercoding-blog/' + path
}

export const mister_coding_logo = cdn2("mistercoding_white_5c7ae6c2c3.png");
// export const mister_coding_logo2 = cdn2("mistercoding_new_60c7279a36.png");
// export const mister_coding_logo2 = "https://mrcodingblog.s3.ap-southeast-1.wasabisys.com/logo_mister_coding3_346be29600.png"
export const mister_coding_logo2 = "https://img.picopixs.com/mrcodingimg/Images/mr_coding_logo_text.png"

export const mister_coding_logo_icon = cdn2('mister_without_text_6dbb22bb78.png')