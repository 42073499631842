import { Box, Flex, Heading, Icon, Text } from "@chakra-ui/react";
import Link from "next/link";
import { FC } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";

type Props = {
    back_url?: string
}
const LiveNotStart: FC<Props> = ({ back_url }) => {
    return (
        <>
            <Box h={"100vh"} bg={"gray.700"} p={8}>
                {
                    back_url && <>
                        <Link href={back_url}>
                            <Flex alignItems={"center"} cursor={"pointer"}>
                                <Icon color={"gray.100"} as={AiOutlineArrowLeft} />
                                <Text ml={1} color={"gray.100"}>Back</Text>
                            </Flex>
                        </Link>
                    </>
                }

                <Flex h={"full"} alignItems={"center"} justifyContent={"center"}>
                    <Heading color={"gray.100"} textAlign={"center"}>Live Session Belum Dimulai</Heading>
                </Flex>
            </Box>
        </>
    )
}

export default LiveNotStart;