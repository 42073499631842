import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Textarea,
} from "@chakra-ui/react";
import { FC, InputHTMLAttributes } from "react";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    register?: any;
    errors?: any;
    title: string;
    label?: string;
    type?: string;
}
export const FormTextarea: FC<Props> = ({
    register,
    errors,
    title,
    label,
    type,
    ...props
}) => {
    return (
        <>
            <FormControl isInvalid={title in errors}>
                <FormLabel>{label}</FormLabel>
                <Textarea {...register} type={type} {...props} />
                <FormErrorMessage>{errors?.[title]?.message}</FormErrorMessage>
            </FormControl>
        </>
    );
};

FormTextarea.defaultProps = {
    type: "text",
};
