import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "@/utils/query/axiosBaseQuery";
import { Event } from "model/event";
import { Order } from "model/order";
import { last } from "lodash";

type ResCheckRegister = {
  is_register: boolean
  is_free: boolean
}

type ResJoinEvent = {
  has_join: boolean
  is_free: boolean
}

export interface CheckOrder {
  success: boolean;
  is_active: boolean;
  order: Order
}

export const eventApi = createApi({
  reducerPath: "eventApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Event"],
  endpoints: (builder) => ({
    getEvents: builder.query<Event[], void>({
      query() {
        return {
          url: "/common/events/all",
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? [],
      providesTags: ["Event"],
    }),
    checkRegister: builder.query<ResCheckRegister, any>({
      query(id_event) {
        return {
          url: "/common/events/check-register/" + id_event,
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? {},
    }),
    joinEvent: builder.mutation<ResJoinEvent, any>({
      query(id_event) {
        return {
          url: "/common/events/join/" + id_event,
          method: "POST",
        };
      },
      transformResponse: (response: any) => response.data ?? {},
    }),
    checkOrderEvent: builder.mutation<CheckOrder, any>({
      query(id_event) {
        return {
          url: "/common/order/check-event/" + id_event,
          method: "POST",
        };
      },
      transformResponse: (response: any) => {
        const data = response.order;
        if (data.payment.length >= 1) {
          data.payment = last(data.payment);
        } else {
          data.payment = null;
        }
        return {...response,order:data,is_active:response.is_active,success:response.success};
      },
    }),
    orderEvent: builder.mutation<Event, any>({
      query({id_event,method}) {
        return {
          url: "/common/order/join-event/"+id_event+"/"+method,
          method: "POST"
        };
      },
      transformResponse: (response: any) => {
        const data = response.data;
        if (data.payment.length >= 1) {
          data.payment = last(data.payment);
        } else {
          data.payment = null;
        }
        return data;
      },
    }),
  }),
});

export const { useLazyGetEventsQuery, useLazyCheckRegisterQuery, useJoinEventMutation, useCheckOrderEventMutation, useOrderEventMutation } = eventApi;
export default eventApi;
