import * as React from "react";
import { Button, ButtonProps } from "@chakra-ui/react";
import { FC, ReactNode } from "react";

interface Props extends ButtonProps {
  children: ReactNode;
  href?: string;
  bg?: string
  color?: string
}

export const MyButton: FC<Props> = ({ children, href, bg, color, ...props }) => {
  return (
    <>
      <Button
        href={href}
        {...props}
        bg={bg ?? "brand.500"}
        color={color??"white"}
        _hover={{ bg: "brand.500", opacity: 0.8 }}
      >
        {children}
      </Button>
    </>
  );
};
