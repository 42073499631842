import {
  Center,
  Box,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { cdn1 } from "libs/utils/image";
import { FC, useState } from "react";
import ReactPlayer from "ui/components/ReactPlayer";
import PlayerButton from "./PlayerButton";


interface Props {
  thumbnail_path?: string;
  youtube_id?: string;
}
const TrailerPopup: FC<Props> = ({ thumbnail_path, youtube_id }) => {
  const {
    isOpen: isOpenVideo,
    onOpen: onOpenVideo,
    onClose: onCloseVideo,
  } = useDisclosure();
  const [showPlay, setShowPlay] = useState(false);
  return (
    <>
      <Modal
        size={"5xl"}
        blockScrollOnMount={false}
        isOpen={isOpenVideo}
        onClose={onCloseVideo}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody>
            <Box
              width={"full"}
              h={{ sm: 650, base: 260 }}
              overflow={"hidden"}
              pb={4}
            >
              <ReactPlayer
                height={"100%"}
                width={"100%"}
                url={`https://www.youtube.com/watch?v=${youtube_id}`}
                controls={true}
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Box
        cursor={"pointer"}
        onMouseOver={() => {
          setShowPlay(true);
        }}
        onMouseOut={() => {
          setShowPlay(false);
        }}
        onClick={onOpenVideo}
        overflow={"hidden"}
      >
        <Image
          opacity={0.9}
          // src={
          //   thumbnail_path
          //     ? `https://img.picopixs.com/mrcoding/${thumbnail_path}?w=600`
          //     : "https://res.cloudinary.com/dlppzoemc/image/upload/v1657176238/jejakcoding/react_t8hb52.png"
          // }
          src={
            thumbnail_path
              ? cdn1(thumbnail_path)
              : "https://res.cloudinary.com/dlppzoemc/image/upload/v1657176238/jejakcoding/react_t8hb52.png"
          }
          alt="Green double couch with wooden legs"
          borderTopStartRadius={4}
          borderTopRightRadius={4}
          w={450}
        />
        {showPlay == true && (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2 }}
          >
            <Center mt={{ sm: -135, base: -116 }} pos={"absolute"} w={"full"}>
              <PlayerButton />
            </Center>
          </motion.div>
        )}
      </Box>
    </>
  );
};

export default TrailerPopup;
