import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "@/utils/query/axiosBaseQuery";
import {Subscribe} from 'model/subscribe';

export const subscribeApi = createApi({
  reducerPath: "subscribeApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    checkSubscribe: builder.query<Subscribe, any>({
      query(token) {
        return {
          url: "/user/check-subscribe/"+token,
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? null,
    }),
    unsubscribe: builder.mutation<boolean, any>({
        query(token) {
          return {
            url: "/user/unsubscribe/"+token,
            method: "POST",
          };
        },
        transformResponse: (response: any) => response.data ?? null,
      })
  }),
});

export const { useLazyCheckSubscribeQuery, useUnsubscribeMutation } = subscribeApi;
export default subscribeApi;
