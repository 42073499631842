import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "@/utils/query/axiosBaseQuery";
import {Campaign} from 'model/campaign';

export const campaignApi = createApi({
  reducerPath: "campaignApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getCampaign: builder.query<Campaign, any>({
      query(slug) {
        return {
          url: "/common/campaign/detail/"+slug,
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? null,
    }),
  }),
});

export const { useLazyGetCampaignQuery } = campaignApi;
export default campaignApi;
