
import { Post } from "model/post";
import { PostTag } from "model/post";
import { LabelValue } from "model/label_value";
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/query/axiosBaseQuery";

export const sharingApi = createApi({
    reducerPath: "sharingApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["sharing"],
    endpoints: (builder) => ({
        getTags: builder.query<PostTag[], void>({
            query() {
                return {
                    url: "/posts/v1/posts/tags",
                    method: "GET"
                };
            },
            transformResponse: (response: any) => response.data ?? [],
        }),
        getPosts: builder.query<Post[], any>({
            query(params) {
                return {
                    url: "/posts/v1/posts/all",
                    method: "GET",
                    params: params
                };
            },
            providesTags: ["sharing"],
            transformResponse: (response: any) => response.data ?? [],
        }),
    }),
});

export const { useGetTagsQuery, useLazyGetPostsQuery, useLazyGetTagsQuery } = sharingApi;
export default sharingApi;
