import { Box } from "@chakra-ui/react";
import { FC, useState } from "react";
// import SimpleMDEditor from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import dynamic from "next/dynamic";

const SimpleMDEditor = dynamic(() => import("react-simplemde-editor"), {
  ssr: false,
});

interface Props {
  value?: string;
  onChange?: any;
}
const MdEditor: FC<Props> = ({ value, onChange }) => {
  return (
    <Box>
      <SimpleMDEditor id="editor" value={value} onChange={onChange} />
    </Box>
  );
};

export default MdEditor;
