import {Kelas} from "model/kelas";
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/query/axiosBaseQuery";
import { first } from "lodash";
import { Article, Attributes } from "model/blog";
import { axiosBaseQueryBlog } from "@/utils/query/axiosBaseQueryBlog";

interface CheckOrder {
  success: boolean;
  is_active: boolean;
}
export const blogApi = createApi({
  reducerPath: "blogApi",
  baseQuery: axiosBaseQueryBlog(),
  endpoints: (builder) => ({
    getBlogs: builder.query<Article[], void>({
      query() {
        return {
          url: "/posts",
          method: "GET",
          params: {
            populate: "*",
          },
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),
    myBlog: builder.query<Kelas[], void>({
      query() {
        return {
          url: "/common/kelas/my-class",
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),
    getBlogDetail: builder.query<Article, any>({
      query({ slug }) {
        return {
          url: "/posts",
          method: "GET",
          params: {
            populate: "*",
            "filters[slug][$eq]": slug,
          },
        };
      },
      transformResponse: (response: any) => response.data[0] ?? [],
    }),
  }),
});

export const { useLazyGetBlogsQuery, useLazyGetBlogDetailQuery } = blogApi;
export default blogApi;
