import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { BaseQueryFn } from "@reduxjs/toolkit/query";
import { api } from "@/config/api";
import { AuthService } from "@/auth/auth.service";

const authService = new AuthService();

export const axiosBaseQueryBlog =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: api.api_blog_url }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      headers?: AxiosRequestConfig["headers"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params, headers }) => {
    axios.interceptors.request.use(
      async (config: any) => {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer 77d1c245cf59e01806766cd2b5c522e68aa9c3d42b04cc959155279a535a9ce5cee62b1eecd473452e466ee2241aad6b4b516ae285f34780ee0188a785a822fdcf771a207feaea1e86949dde6983f4546d881d70c46d7b721064f0a51bb93d0e8f96bd4aa49c4863fad14f6cec2ba848a02f01ead2a22d9f94bbafcde25e89a5`,
        };
        return config;
      },
      (error) => Promise.reject(error)
    );

    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
