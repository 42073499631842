import { Course } from "model/course";
import { Bootcamp, BootcampSilabus } from "model/bootcamp";
import { Certificate } from "model/certificate";
import { CourseCategory } from "model/course_category";
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/query/axiosBaseQuery";
import { first, last } from "lodash";

interface CheckOrder {
  success: boolean;
  is_active: boolean;
}
export const kelasApi = createApi({
  reducerPath: "kelasApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getKelas: builder.query<Course[], any>({
      query(q = null) {
        return {
          url: "/common/kelas/new-class",
          method: "GET",
          params: q,
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),
    getCertificate: builder.query<Certificate, any>({
      query(no) {
        return {
          url: "/common/certificate/check/" + no,
          method: "GET"
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),
    myKelas: builder.query<Course[], void>({
      query() {
        return {
          url: "/common/kelas/my-class",
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),
    getKelasDetail: builder.query<Course, any>({
      query({ slug }) {
        return {
          url: "/common/kelas/detail-class/" + slug,
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),
    getBootcampBatch: builder.query<Bootcamp, any>({
      query(course_id) {
        return {
          url: "/common/kelas/batch/" + course_id,
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),
    joinClass: builder.mutation<Course, any>({
      query(data) {
        return {
          url: "/common/kelas/join",
          method: "POST",
          data: data,
        };
      },
      transformResponse: (response: any) => {
        const data = response.data;

        if (data.payment.length >= 1) {
          data.payment = last(data.payment);
        } else {
          data.payment = null;
        }
        return data;
      },
    }),
    joinBootcamp: builder.mutation<Course, any>({
      query({data,id_batch}) {
        return {
          url: "/common/order/join-bootcamp/"+id_batch,
          method: "POST",
          data: data,
        };
      },
      transformResponse: (response: any) => {
        const data = response.data;

        if (data.payment.length >= 1) {
          data.payment = last(data.payment);
        } else {
          data.payment = null;
        }
        return data;
      },
    }),
    checkOrder: builder.mutation<CheckOrder, any>({
      query(id_course) {
        return {
          url: "/common/order/check/" + id_course,
          method: "POST",
        };
      },
      transformResponse: (response: any) => response ?? {},
    }),
    checkOrderBootcamp: builder.mutation<CheckOrder, any>({
      query(id_batch) {
        return {
          url: "/common/order/check-bootcamp/" + id_batch,
          method: "POST",
        };
      },
      transformResponse: (response: any) => response ?? {},
    }),
    getCategories: builder.query<CourseCategory[], void>({
      query() {
        return {
          url: "/common/kelas/categories",
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),
    getSilabuses: builder.query<BootcampSilabus[], any>({
      query(id_course) {
        return {
          url: "/bootcamp/silabus/all/" + id_course,
          method: "GET",
        };
      },
      transformResponse: (response: any) => response.data ?? [],
    }),
  }),
});

export const {
  useLazyGetKelasQuery,
  useGetKelasQuery,
  useGetKelasDetailQuery,
  useLazyGetKelasDetailQuery,
  useJoinClassMutation,
  useCheckOrderMutation,
  useLazyMyKelasQuery,
  useLazyGetCategoriesQuery,
  useLazyGetCertificateQuery,
  useLazyGetBootcampBatchQuery,
  useCheckOrderBootcampMutation,
  useJoinBootcampMutation,
  useLazyGetSilabusesQuery
} = kelasApi;
export default kelasApi;
